<script setup>
import {computed, ref} from "vue";
import {Link} from '@inertiajs/vue3'
import {chevronDown} from "ionicons/icons";
import {IonIcon} from "@ionic/vue";
import {directive as vClickOutside} from 'click-outside-vue3'
import route from "ziggy-js";

const props = defineProps({
    title: String,
    url: String,
    collapsing: Boolean,
    right: Boolean,
    method: {
        type: String,
        default: 'get'
    },
    type: {
        type: String,
        default: null
    },
    otherCategory: {
        type: Array,
        default: null
    }
})

const showingSubMenus = ref(false);
const onClickOutside = () => {
    if (showingSubMenus.value) showingSubMenus.value = false
}

const isActive = computed(() => {
    if (props.collapsing) {
        return props.otherCategory.some(item => route().current('category', item.slug))
    }
    if(props.type === 'home') {
        return route().current(props.type)
    }
    return route().current('category', props.type)
})

</script>

<template>
    <li class="text-gray-400 font-medium text-sm flex items-center px-2 hover:text-blue-400 uppercase">
        <div v-if="collapsing" v-click-outside="onClickOutside" class="h-full">
            <div
                class="flex items-center h-full justify-center"
            >
                <a :class="{'text-blue-400 font-medium': isActive}" href="#"
                   @click.prevent="showingSubMenus = !showingSubMenus"
                class="whitespace-nowrap">
                    <span class="align-middle">{{ title }}</span>
                    <ion-icon :class="{
                'rotate-180' : showingSubMenus,
            }" :icon="chevronDown" class="text-2xl transition-transform align-middle"></ion-icon>
                </a>
                <slot/>
            </div>
            <div v-if="showingSubMenus" class="absolute flex items-center px-2.5 bg-deep-blue -ml-4 z-10 ">
                <slot name="subMenu"/>
            </div>
        </div>
        <Link v-else
              :href="url"
              :method="method"
              :class="{'text-blue-400 font-medium': isActive}"
        >
            <span class="whitespace-nowrap">{{ title }}</span>
            <slot/>
        </Link>
        <slot name="doth"/>
    </li>
</template>

<style scoped>

</style>

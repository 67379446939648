<script setup>
import {computed, ref, watch} from "vue";
import SideBarMenuItem from "@/Components/Menus/SideBarMenuItem.vue";
import Logo from "@/Components/Icons/Logo.vue";
import {ellipse, gameController, helpCircle, home as HomeIcon, key, library} from "ionicons/icons";
import {IonIcon} from '@ionic/vue';
import route from "ziggy-js";
import {usePage} from "@inertiajs/vue3";
import {HelpDeskUrl} from "@/constants.js";

const props = defineProps({
    modelValue: Boolean,
})
const emit = defineEmits(['update:modelValue']);
const showSideBar = ref(false),
    page = usePage(),
    categoriesWithSubcategories = computed(() => {
        const categories = page.props.categories.reduce((acc, category) => {
            if (!category.parent_id) {
                acc[category.id] = {...category};
                acc[category.id].subcategories = [];
            }
            return acc;
        }, {});
        page.props.categories.forEach(category => {
            if (category.parent_id) {
                categories[category.parent_id].subcategories.push(category);
            }
        });
        return categories;
    });

watch(() => props.modelValue, (val) => {
    if (val) {
        setTimeout(() => {
            showSideBar.value = true
        }, 1)
    } else {
        showSideBar.value = false
    }
})
const close = () => {
    showSideBar.value = false
    setTimeout(() => {
        emit('update:modelValue', false)
    }, 200)
}
</script>

<template>
    <div v-if="modelValue" class="fixed left-0 top-0 h-screen flex w-screen z-20">
        <aside :class="{'-translate-x-96' : !showSideBar}"
               class="h-full w-3/5 transition-transform ease-out duration-200 max-w-[240px] sm:max-w-[512px]">
            <div class="h-full px-2 pt-8 py-4 overflow-y-auto bg-deep-blue">
                <div class="flex items-center justify-center">
                    <Logo class="self-center"/>
                </div>
                <ul class="font-medium pt-2">
                    <SideBarMenuItem :title="$t('HOME')" :url="route('home')" right>
                        <ion-icon :icon="HomeIcon" class="text-2xl"></ion-icon>
                    </SideBarMenuItem>
                    <template v-for="category in categoriesWithSubcategories">
                        <SideBarMenuItem v-if="category.subcategories.length === 0"
                                         :url="route('category', category.slug)" right
                                         :title="category.title" :type="category.slug">
                            <template v-if="category.slug === 'games'">
                                <ion-icon :icon="gameController" class="text-2xl"></ion-icon>
                            </template>
                            <template v-else>
                                <ion-icon :icon="key" class="text-2xl"></ion-icon>
                            </template>
                        </SideBarMenuItem>
                        <SideBarMenuItem v-else :other-category="category.subcategories" :title="category.title"
                                         url="#" collapsing right>
                            <template #subMenu>
                                <ul>
                                    <template v-for="subCategory in category.subcategories">
                                        <SideBarMenuItem right :title="subCategory.title"
                                                         :url="route('category', subCategory.slug)"
                                                         :type="subCategory.slug">
                                            <ion-icon :icon="ellipse" class="text-xs"></ion-icon>
                                        </SideBarMenuItem>
                                    </template>
                                </ul>
                            </template>
                            <ion-icon :icon="library" class="text-2xl"></ion-icon>
                        </SideBarMenuItem>
                    </template>
                    <li class="border-b border-dashed border-gray-200 last:border-none text-gray-300 text-sm
  hover:text-blue-400 hover:border-blue-400 uppercase">
                        <a
                            :href="HelpDeskUrl"
                            class="flex items-center justify-end py-3"
                            target="_blank"
                        >
                            <span class="mx-2">{{ $t('HELP CENTER') }}</span>
                            <ion-icon :icon="helpCircle" class="text-2xl"></ion-icon>
                        </a>
                    </li>

                    <!--                    <li>-->
                    <!--                        <a class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"-->
                    <!--                           href="#">-->
                    <!--                            <svg aria-hidden="true"-->
                    <!--                                 class="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"-->
                    <!--                                 fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">-->
                    <!--                                <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>-->
                    <!--                                <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>-->
                    <!--                            </svg>-->
                    <!--                            <span class="ml-3">Dashboard</span>-->
                    <!--                        </a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"-->
                    <!--                           href="#">-->
                    <!--                            <svg aria-hidden="true"-->
                    <!--                                 class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"-->
                    <!--                                 fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">-->
                    <!--                                <path-->
                    <!--                                    d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>-->
                    <!--                            </svg>-->
                    <!--                            <span class="flex-1 ml-3 whitespace-nowrap">Kanban</span>-->
                    <!--                            <span-->
                    <!--                                class="inline-flex items-center justify-center px-2 ml-3 text-sm font-medium text-gray-800 bg-gray-200 rounded-full dark:bg-gray-700 dark:text-gray-300">Pro</span>-->
                    <!--                        </a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"-->
                    <!--                           href="#">-->
                    <!--                            <svg aria-hidden="true"-->
                    <!--                                 class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"-->
                    <!--                                 fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">-->
                    <!--                                <path-->
                    <!--                                    d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"></path>-->
                    <!--                                <path-->
                    <!--                                    d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"></path>-->
                    <!--                            </svg>-->
                    <!--                            <span class="flex-1 ml-3 whitespace-nowrap">Inbox</span>-->
                    <!--                            <span-->
                    <!--                                class="inline-flex items-center justify-center w-3 h-3 p-3 ml-3 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">3</span>-->
                    <!--                        </a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"-->
                    <!--                           href="#">-->
                    <!--                            <svg aria-hidden="true"-->
                    <!--                                 class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"-->
                    <!--                                 fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">-->
                    <!--                                <path clip-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"-->
                    <!--                                      fill-rule="evenodd"></path>-->
                    <!--                            </svg>-->
                    <!--                            <span class="flex-1 ml-3 whitespace-nowrap">Users</span>-->
                    <!--                        </a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"-->
                    <!--                           href="#">-->
                    <!--                            <svg aria-hidden="true"-->
                    <!--                                 class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"-->
                    <!--                                 fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">-->
                    <!--                                <path clip-rule="evenodd"-->
                    <!--                                      d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z"-->
                    <!--                                      fill-rule="evenodd"></path>-->
                    <!--                            </svg>-->
                    <!--                            <span class="flex-1 ml-3 whitespace-nowrap">Products</span>-->
                    <!--                        </a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"-->
                    <!--                           href="#">-->
                    <!--                            <svg aria-hidden="true"-->
                    <!--                                 class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"-->
                    <!--                                 fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">-->
                    <!--                                <path clip-rule="evenodd"-->
                    <!--                                      d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"-->
                    <!--                                      fill-rule="evenodd"></path>-->
                    <!--                            </svg>-->
                    <!--                            <span class="flex-1 ml-3 whitespace-nowrap">Sign In</span>-->
                    <!--                        </a>-->
                    <!--                    </li>-->
                    <!--                    <li>-->
                    <!--                        <a class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"-->
                    <!--                           href="#">-->
                    <!--                            <svg aria-hidden="true"-->
                    <!--                                 class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"-->
                    <!--                                 fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">-->
                    <!--                                <path clip-rule="evenodd"-->
                    <!--                                      d="M5 4a3 3 0 00-3 3v6a3 3 0 003 3h10a3 3 0 003-3V7a3 3 0 00-3-3H5zm-1 9v-1h5v2H5a1 1 0 01-1-1zm7 1h4a1 1 0 001-1v-1h-5v2zm0-4h5V8h-5v2zM9 8H4v2h5V8z"-->
                    <!--                                      fill-rule="evenodd"></path>-->
                    <!--                            </svg>-->
                    <!--                            <span class="flex-1 ml-3 whitespace-nowrap">Sign Up</span>-->
                    <!--                        </a>-->
                    <!--                    </li>-->
                </ul>
            </div>
        </aside>
        <div class="w-2/5 bg-transparent" @click="close"></div>
    </div>
    <div v-if="modelValue" class="bg-black dark:bg-slate-200 opacity-50 z-10 fixed left-0 top-0 h-screen w-screen"></div>
</template>
